<div class="navbar-custom">
    <div class="container-fluid">
        <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                <img src="assets/images/jsw-logo-white.png" alt="brand" />
            </li>
        </ul>
        <ul class="navbar-nav list-unstyled topnav-menu float-end">
            <li class="nav-item dropdown nav-user">
                <ng-container *ngIf="displayName === null">
                    <span
                        class="pro-user-name ms-1 hide dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i class="ri-settings-4-line"></i>
                    </span>
                    <ul class="dropdown-menu">
                        <li>
                            <a
                                class="dropdown-item"
                                routerLink="/vendor-profile"
                            >
                                <i class="ri-user-3-line"></i>
                                <span class="ml-2">Vendor Profile</span>
                            </a>
                        </li>
                        <li>
                            <a
                                class="dropdown-item"
                                routerLink="/change-password"
                            >
                                <i class="ri-lock-2-line"></i>
                                <span class="ml-2">Change Password</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" (click)="accountLogout()">
                                <i class="ri-logout-box-line"></i>
                                <span class="ml-2"> Logout</span>
                            </a>
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="displayName !== null">
                    <span
                        class="pro-user-name ms-1 dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {{ displayName }}
                    </span>
                    <ul class="dropdown-menu">
                        <li>
                            <a
                                class="dropdown-item"
                                routerLink="/vendor-profile"
                            >
                                <i class="ri-user-3-line"></i>
                                <span class="ml-2">Vendor Profile</span>
                            </a>
                        </li>
                        <li>
                            <a
                                class="dropdown-item"
                                routerLink="/change-password"
                            >
                                <i class="ri-lock-2-line"></i>
                                <span class="ml-2">Change Password</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" (click)="accountLogout()">
                                <i class="ri-logout-box-line"></i>
                                <span class="ml-2"> Logout</span>
                            </a>
                        </li>
                    </ul>
                </ng-container>
            </li>
        </ul>
    </div>
</div>
