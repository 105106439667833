import {
    ApexAxisChartSeries,
    ApexChart,
    ApexFill,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexStroke,
    ApexXAxis,
    ApexYAxis,
} from "ng-apexcharts";

export const StringConstants = {
    USER_sessionStr: "payex_dc_session",
    USER_landingstateStoreKey: "landingState",
    STORAGE_KEYS: {
        PAYEXAP_INVOICE: "payexap_invoice",
        PAYEXAP_SELECTED_INVOICES: "payexap_selected_invoices",
        PAYEXAP_SELECTED_TRANSACTION: "payexap_selected_transaction",
        PAYEXAP_SELLER: "payexap_seller",
        PAYEXAP_SUPPLIER: "payexap_supplier",
        PAYEXAP_PURCHASE_ODER: "payexap_purchase_order",
        PAYEXAP_POD: "payexap_pod",
        PAYEXAP_RECON: "payexap_recon",
        PADD_CURRENT_OFFER: "padd_current_offer",
    },
    EVENTS: {
        APP_BUSY: "app_busy",
        APP_IDLE: "app_idle",
        USER_LOGOUT: "app_logout",
    },
    MESSAGES: {
        PLEASE_WAIT: "Please Wait...",
        INVALID_SERVICE_RESPONSE:
            "We are unable to process your request at the moment, please try again later.",
        SERVER_GENERAL_ERROR:
            "Oops! There seems to be server issue, please try again.",
        SESSION_TIMEOUT:
            "Your session has timed-out because of inactivity, Please login again to continue.",
        DOC_NOT_FOUND: "Document not found",
    },
    PO_ACCEPTED_FILE_FORMATS: ".csv,.xlsx",
    INVOICE_ACCEPTED_FILE_FORMATS: ".pdf,.PDF",
    RECON_ACCEPTED_FILE_FORMATS: ".csv,.xlsx,.pdf,.PDF",
    PAYMENT_RESPONSE_FILE_FORMATS: ".json,.xlsx,.pdf,.PDF,csv",
};
export enum PayExAppTypeEnum {
    PAYEX_AP = "Payex-Ap",
}
export enum UserRolesEnum {
    PADD_ADMIN = "PADD-Admin",
    SELLER_ADMIN = "Seller-Admin",
    SUPPLIER_ADMIN = "Supplier",
}
export enum ReqTypeEnum {
    PAYEXAP = "payexap",
}
export enum UploadModeEnum {
    INVOICE = "invoice",
    PURCHASE_ORDER = "po",
    POD = "pod",
    RESOLULTION = "resolution",
    SUPPLIER = "supplier",
}
export enum PaginationEnum {
    PAGE = 1,
    PAGE_SIZE = 10,
}
export enum MatchStatusEnum {
    PARTIALLY_MATCHED = "PARTIALLY_MATCHED",
    FULLY_MATCHED = "FULLY_MATCHED",
}
export const radiarBarChartOption: ApexChart = {
    width: 200,
    type: "radialBar",
};
export const radiarBarPlotOption: ApexPlotOptions = {
    radialBar: {
        offsetX: 0,
        offsetY: 0,
        hollow: {
            size: "40%",
            margin: 0,
        },
        track: {
            show: true,
        },
        dataLabels: {
            show: true,
            value: {
                offsetY: 8,
                fontSize: "20px",
                fontWeight: 600,
            },
            name: {
                show: false,
            },
        },
    },
};

export const barGraphChartOption: ApexChart = {
    type: "bar",
    height: 430,
};
export const barGraphPlotOption = {
    bar: {
        horizontal: false,
        dataLabels: {
            enabled: false,
            position: "bottom",
        },
    },
};

export const barGraphNoDataLabelPlotOption: ApexPlotOptions = {
    bar: {
        horizontal: false,
        dataLabels: {
            // enabled: false
        },
    },
};

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    stroke: ApexStroke;
    labels: string[];
    fill: ApexFill;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    plotOptions: ApexPlotOptions;
};

export type BarChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    stroke: ApexStroke;
    labels: string[];
    fill: ApexFill;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    color: string[];
    plotOptions: ApexPlotOptions;
};

export enum EmailRequestDocType {
    INV_POD_CREDIT_NOTE = "INV_POD_CREDIT_NOTE",
    INV_PO_CREDIT_NOTE = "INV_PO_CREDIT_NOTE",
}

export enum POStatusEnum {
    OPEN = "OPEN",
    REJECTED = "REJECTED",
    DEACTIVATED = "DEACTIVATED",
}

export enum PODStatusEnum {
    OPEN = "OPEN",
    REJECTED = "REJECTED",
    DEACTIVATED = "DEACTIVATED",
}

export enum InvoiceStatusEnum {
    UNPAID = "UNPAID",
    REJECTED = "REJECTED",
    ACCEPTED = "ACCEPTED",
    PARTIALLY_PAID = "PARTIALLY_PAID",
    FULLY_PAID = "FULLY_PAID",
}
