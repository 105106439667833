<!-- <div id="sidebar" class="d-flex flex-column flex-shrink-0 sidebar minimised">
    <span
        class="d-flex brand align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none py-4 mr-2"
    >
        <img src="assets/images/jsw-logo-white.png" alt="brand" />
    </span>
    <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-white py-3" routerLink="/dashboard">
                <i class="ri-dashboard-line mr-4"></i>
                <span class="ml-2">Dashboard</span>
            </a>
        </li>
        <li routerLinkActive="active">
            <a class="nav-link text-white py-3" routerLink="/purchase-orders">
                <i class="ri-bill-line"></i>
                <span class="ml-2">Purchased Orders</span>
            </a>
        </li>
        <li routerLinkActive="active">
            <a class="nav-link text-white py-3" routerLink="/invoices">
                <i class="ri-survey-line"></i><span class="ml-2">Invoices</span>
            </a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/credit-notes" class="nav-link text-white py-3"
                ><i class="ri-file-list-3-line"></i>
                <span class="ml-2">Credit Notes</span>
            </a>
        </li>
    </ul>
</div> -->

<!-- ========== Left Sidebar Start ========== -->

<!-- Left Sidebar End -->
<ng-template #contentTemplate>
    <div id="sidebar-menu">
        <ul class="metismenu" id="side-menu" #sideMenu>
            <li class="menu-title">Navigation</li>
            <li>
                <a
                    routerLink="/dashboard"
                    class="waves-effect side-nav-link-ref"
                    routerLinkActive="active"
                >
                    <i class="ri-dashboard-line"></i>
                    <span> Dashboard </span>
                </a>
            </li>
            <li>
                <a
                    class="waves-effect side-nav-link-ref"
                    href="javascript:void(0)"
                    data-bs-toggle="collapse"
                    data-bs-target="#menuDropDown"
                    aria-expanded="false"
                    aria-controls="menuDropDown"
                >
                    <i class="ri-bill-line"></i>
                    <span class="ml-2">Submit Invoice</span>
                    <i class="ri-arrow-down-s-fill"></i>
                </a>
                <div class="collapse" id="menuDropDown">
                    <ul class="nav-second-level">
                        <li>
                            <a
                                routerLink="/purchase-orders"
                                class="waves-effect side-nav-link-ref"
                                routerLinkActive="active"
                                >PO Based</a
                            >
                        </li>
                        <li *ngIf="canSubmitLogistics === 'TRUE'">
                            <a
                                routerLink="/submit-logistics-invoice"
                                class="waves-effect side-nav-link-ref"
                                routerLinkActive="active"
                                >Logistics</a
                            >
                        </li>
                        <li *ngIf="canSubmitNonPo === 'TRUE'">
                            <a
                                routerLink="/submit-nonpo-invoice"
                                class="waves-effect side-nav-link-ref"
                                routerLinkActive="active"
                                >Non PO Based</a
                            >
                        </li>
                    </ul>
                </div>
            </li>

            <li>
                <a
                    routerLink="/invoices"
                    class="waves-effect side-nav-link-ref"
                    routerLinkActive="active"
                >
                    <i class="ri-survey-line"></i
                    ><span class="ml-2">Invoices</span>
                </a>
            </li>

            <!-- <li>
                <a (click)="logout()" class="waves-effect side-nav-link-ref">
                    <i class="ri-logout-box-line"></i>
                    <span> Logout </span>
                </a>
            </li> -->
        </ul>
    </div>
    <!-- End Sidebar -->
    <div class="clearfix"></div>

    <!--- Sidemenu -->

    <!-- End Sidebar -->

    <div class="clearfix"></div>
</ng-template>

<ng-template #contentTemplateBuyer>
    <div id="sidebar-menu">
        <ul class="metismenu" id="side-menu" #sideMenu>
            <li class="menu-title">Navigation</li>
            <li>
                <a
                    routerLink="/buyer-analytics"
                    class="waves-effect side-nav-link-ref"
                    routerLinkActive="active"
                >
                    <i class="ri-store-2-line"></i>
                    <span> Vendors </span>
                </a>
            </li>
        </ul>
    </div>
    <!-- End Sidebar -->
    <div class="clearfix"></div>

    <!--- Sidemenu -->

    <!-- End Sidebar -->

    <div class="clearfix"></div>
</ng-template>

<div class="left-side-menu">
    <div class="logo-box">
        <span class="logo logo-light text-center">
            <span class="logo-sm">
                <button
                    class="button-menu-mobile waves-effect waves-light btn-transparent"
                    (click)="toggleSidebar()"
                >
                    <i class="ri-menu-line"></i>
                </button>
            </span>
            <span class="logo-lg">
                <button
                    class="button-menu-mobile waves-effect waves-light btn-transparent"
                    (click)="toggleSidebar()"
                >
                    <i class="ri-menu-line"></i>
                </button>
            </span>
        </span>
    </div>
    <div class="slimscroll-menu" appSlimScroll *ngIf="!isCondensed && !isBuyer">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div class="slimscroll-menu" *ngIf="isCondensed && !isBuyer">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>

    <div class="slimscroll-menu" appSlimScroll *ngIf="!isCondensed && isBuyer">
        <ng-container *ngTemplateOutlet="contentTemplateBuyer"></ng-container>
    </div>
    <div class="slimscroll-menu" *ngIf="isCondensed && isBuyer">
        <ng-container *ngTemplateOutlet="contentTemplateBuyer"></ng-container>
    </div>
</div>
