import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
  ) { }

  add(key, obj) {
    return sessionStorage.setItem(key, obj);
  }

  get(key) {
    return sessionStorage.getItem(key);
  }

  remove(keys) {
    keys.forEach(key => {
      return sessionStorage.removeItem(key)
    });
  }
}
