import {
    Component,
    OnInit,
    AfterViewInit,
    ElementRef,
    ViewChild,
    Input,
    OnChanges,
} from "@angular/core";
import MetisMenu from "metismenujs";
import { AuthService } from "src/app/_app-core/services/auth/auth.service";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() isCondensed = false;
    @Input() options: any = {};
    menu: any;
    menuItems = [];
    displayName: any;
    isBuyer = false;
    //isCondensed: boolean = false;
    currentLayout = {
        isCondensed: true,
    };
    responseData: any;
    vendorData: any;
    canSubmitLogistics: any;
    canSubmitNonPo: any;

    @ViewChild("sideMenu", { static: false }) sideMenu?: ElementRef;

    constructor(private authService: AuthService) {}

    toggleSidebar() {
        this.currentLayout.isCondensed = !this.currentLayout.isCondensed;
        document.body.setAttribute(
            "data-sidebar-size",
            this.currentLayout.isCondensed ? "default" : "condensed"
        );
    }

    ngOnInit(): void {
        this.displayName = localStorage.getItem("displayName");

        if (this.displayName === null) {
            this.isBuyer = true;
        }

        this.canSubmitLogistics = localStorage.getItem("logisticFlag");
        this.canSubmitNonPo = localStorage.getItem("nonPoFlag");
    }

    private setMenu() {
        setTimeout(() => {
            this.menu = new MetisMenu("#side-menu");
        }, 100);
        //this._activateMenuDropdown();
    }

    ngAfterViewInit() {
        this.setMenu();
    }

    ngOnChanges() {
        if (!this.isCondensed && this.sideMenu) {
            this.setMenu();
        } else if (this.menu) {
            this.menu.dispose();
        }
    }

    /**
     * Activates the menu dropdown
     */
}
