<div class="modal-header">
    <h4 class="text-white">Accept Invoice</h4>
    <button
        type="button"
        class="modal-button close"
        aria-describedby="modal-title"
        (click)="activeModal.close(false)"
    >
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</div>
<div class="modal-body">
    <p>Are you sure you want to approve this shipping invoice?</p>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-outline-white"
        (click)="activeModal.close(false)"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-white"
        ngbAutofocus
        (click)="acceptInvoice()"
    >
        Approve
    </button>
</div>
