import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
//import { ClickOutsideModule } from 'ng-click-outside';

import { UIModule } from "../../_shared/ui/ui.module";
import { BaseComponent } from "./base.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { FooterComponent } from "./footer/footer.component";
import { RightsidebarComponent } from "./rightsidebar/rightsidebar.component";

@NgModule({
    declarations: [
        BaseComponent,
        SidebarComponent,
        TopbarComponent,
        FooterComponent,
        RightsidebarComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgbDropdownModule,
        //ClickOutsideModule,
        //UIModule,
    ],
    exports: [
        BaseComponent,
        SidebarComponent,
        TopbarComponent,
        FooterComponent,
        RightsidebarComponent,
    ],
})
export class BaseTheme {}
