import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Config } from "../_store/config";

@Injectable({
    providedIn: "root",
})
export class NavigationService {
    private history: string[] = [];

    constructor(private router: Router, private location: Location) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    back(url = "") {
        this.history.pop();
        if (this.history.length > 0) {
            this.location.back();
        } else {
            this.router.navigateByUrl(url || "/");
        }
    }

    navigate(url) {
        this.router.navigateByUrl(url);
    }

    getLastRoute() {
        return this.history[this.history.length - 1];
    }

    navigateToSignIn(fromLogout = false) {
        const externalURL = Config.externalLoginUrl;
        if (externalURL) {
            //window.location.href = Config.externalLoginUrl;
            if (fromLogout) {
                this.router.navigateByUrl("/landing/exit");
            } else {
                this.locationNavigation(externalURL);
            }
        } else {
            this.router.navigate(["/"]);
        }
    }

    locationNavigation(url) {
        window.location.href = url;
    }
}
