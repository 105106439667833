import { Injectable } from "@angular/core";
import { Config } from "../_store";

@Injectable({
    providedIn: "root",
})
export class AppConfigService {
    urls = Object.freeze(Config.urls);
    pageSize = Config.pageSize;
    menuItems = Object.freeze(Config.menuItems);
    appName = Object.freeze(Config.appName);
    themeConfig = Object.freeze(Config.themeConfig.Algoriq.logo);

    constructor() {}
}
