import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AdminDataService } from "src/app/_services/admin.data.service";
import { AuthenticationService } from "src/app/_services/authenticationService";
import { DialogServiceService } from "src/app/_app-core/dialogs/dialog-service.service";

@Component({
    selector: "app-accept-invoice",
    templateUrl: "./accept-invoice.component.html",
})
export class AcceptInvoiceComponent implements OnInit {
    currentUserData;

    constructor(
        public activeModal: NgbActiveModal,
        private adminService: AdminDataService,
        private authService: AuthenticationService,
        private dialogsService: DialogServiceService
    ) {}

    ngOnInit(): void {}

    acceptInvoice() {
        const acceptParams = this.adminService.getVesonParams();
        console.log(acceptParams);

        this.currentUserData = this.authService.currentUserData;
        const userData = this.currentUserData;
        let session;
        if (userData === null) {
            session = {
                sessionId: sessionStorage.getItem("sessionId"),
            };
        } else {
            session = userData.loginData.session;
        }

        const reqData = {
            action: acceptParams.action,
            barcode: acceptParams.barcode,
            session: session,
        };

        this.activeModal.close(true);
        this.dialogsService.showWaitDialog();

        this.adminService.shippingInvoiceAction(reqData).subscribe(
            (data) => {
                if (data.status === "successful" || data.status === "success") {
                    setTimeout(() => {
                        this.dialogsService.hideWaitDialog();
                    }, 400);
                    const title = "Approved";
                    const msg =
                        "Shipping invoice has been approved successfully";
                    this.dialogsService.showInfoDialog(title, msg);
                }
            },
            (error) => {
                console.log(error);
                this.dialogsService.hideWaitDialog();
            }
        );
    }
}
