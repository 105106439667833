import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/_services";
import { EventsService } from "src/app/_services/eventsService";
import { LayoutService } from "./layoutService";

@Component({
    selector: "app-layout",
    templateUrl: "./base.component.html",
    styleUrls: ["./base.component.scss"],
})
export class BaseComponent implements OnInit, AfterViewInit {
    isCondensed = this.layoutService.currentLayOut.isCondensed;

    constructor(
        private layoutService: LayoutService,
        private eventsService: EventsService
    ) {}

    ngOnInit() {
        this.eventsService.appEvent.subscribe((data) => {});
    }

    ngAfterViewInit() {
        document.body.classList.remove("authentication-bg");
        this.setLayout();
    }

    /**
     * on settings button clicked from topbar
     */
    onSettingsButtonClicked() {
        //document.body.classList.toggle('right-bar-enabled');
        //document.body.classList.toggle('enlarged');
    }

    /**
     * On mobile toggle button clicked
     */
    onToggleMobileMenu() {
        this.isCondensed = !this.isCondensed;
        this.layoutService.currentLayOut.isCondensed = this.isCondensed;
        this.setSidebar();
    }

    setLayout() {
        document.body.classList.add("left-side-menu-light");
        document.body.classList.add("topbar-light");
        document.body.classList.remove("left-side-menu-sm");
    }

    setSidebar() {
        if (this.isCondensed) {
            document.body.setAttribute("data-sidebar-size", "default");
        } else {
            document.body.setAttribute("data-sidebar-size", "condensed");
        }
    }

    onActivate(event) {}
}
