<div class="modal-header">
    <h4 class="text-center text-white">Reject Invoice</h4>
    <button
        type="button"
        class="modal-button close"
        aria-describedby="modal-title"
        (click)="activeModal.close(false)"
    >
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</div>
<div class="modal-body">
    <p>Are you sure you want to Reject this shipping invoice?</p>

    <div class="mt-3">
        <label class="form-label">Reason</label>

        <textarea
            class="form-control"
            aria-label="With textarea"
            (keyup)="userReason($event)"
        ></textarea>

        <p class="help-text-bg" [hidden]="!emptyReason">
            Please enter your reason for rejecting the invoice to proceed.
        </p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-white" (click)="closeModal()">
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-white"
        ngbAutofocus
        (click)="rejectInvoice()"
    >
        Reject
    </button>
</div>
