import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/_services/authenticationService";

@Component({
    selector: "app-topbar",
    templateUrl: "./topbar.component.html",
    styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
    notificationItems: Array<{}>;
    openMobileMenu: boolean;
    username = "";
    userData;

    @Output() settingsButtonClicked = new EventEmitter();
    @Output() mobileMenuButtonClicked = new EventEmitter();

    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {}

    ngOnInit() {
        // get the language
        //this.openMobileMenu = false;
        //
        this.userData = this.authService.currentUserData;

        this.username =
            this.userData.displayName ||
            this.userData.userName ||
            sessionStorage.getItem("displayName");

        //this.username = "Test User";
    }

    /**
     * Toggles the right sidebar
     */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        console.log("clicked " + JSON.stringify(event));
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Logout the user
     */

    logout() {
        const session = this.userData.loginData.session;

        let req;
        if (this.userData === null) {
            this.router.navigate(["signin"]);
        } else {
            req = {
                session: session,
            };

            this.authService.logout(req);
            this.router.navigate(["signin"]);
        }
    }
}
