import { Injectable } from '@angular/core';
import { CommonService } from '.';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class OfflineService {

    constructor(private commonService: CommonService) {
    }

    getDummyResponse(request) {
        const serviceUrl = request.url.substring(request.url.lastIndexOf('/'));
        let res = undefined;
        switch (serviceUrl) {
            case '/getKnockOffEligibleBuyers12':
                res = this.getEligibleBuyersForKnockOff();
                break;
            case '/getDocumentsForBuyers1':
                res = this.getDocumentsForBuyers(request);
                break;
            default:
                res = undefined;
        }
        return res;
    }

    private getBuyers() {
        return [1, 2, 3, 5, 6, 7, 8, 9].map(no => {
            return {
                buyerCode: `buyer00${no}`,
                buyerName: `Buyer Name${no}`
            }
        });
    }

    private getEligibleBuyersForKnockOff() {
        return {
            msg: "",
            status: true,
            data: {
                buyers: this.getBuyers()
            }
        }
    }

    private generateDocumentsForBuyer(buyerCode) {
        return [1, 2, 3, 5, 6, 7, 8, 9].map(no => {
            return {
                docId: `${buyerCode}_00${no}`,
                docNo: `${buyerCode}_00${no}`,
                dueAmt: this.commonService.getRandomNo(1, 100000),
                docDate: '2022-03-11',
                dueDate: '2022-04-11',
                docType: (no % 2 === 0 ? 'INV' : 'DN'),
                buyerCode: buyerCode,
            }
        });
    }

    private getDocumentsForBuyers(req) {
        const buyerCodes = req.body.buyerCodes;
        const creditNotes = [];
        const documents = [];
        buyerCodes.forEach(buyerCode => {
            creditNotes.push(...this.generateDocumentsForBuyer(buyerCode))
            documents.push(...this.generateDocumentsForBuyer(buyerCode))
        });
        return {
            msg: "",
            status: true,
            data: {
                documents: documents,
                creditNotes: creditNotes
            }
        }
    }


}
