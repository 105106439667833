import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthLayoutComponent } from "./_layouts/authentication/authlayout.component";
import { BaseComponent } from "./_layouts/theme/base.component";
import { AuthGuard } from "./_guards";

const routes: Routes = [
    // {
    //     path: "",
    //     component: BaseComponent,
    //     canActivate: [AuthGuard],
    //     loadChildren: () =>
    //         import("./home/home.module").then((m) => m.HomeModule),
    // },
    {
        path: "",
        component: AuthLayoutComponent,
        loadChildren: () =>
            import("./authentication/authentication.module").then(
                (m) => m.AuthenticationModule
            ),
    },
    {
        path: "",
        canActivate: [AuthGuard],
        component: BaseComponent,
        loadChildren: () =>
            import("./home/home.module").then((m) => m.HomeModule),
    },
    {
        path: "",
        canActivate: [AuthGuard],
        component: BaseComponent,
        loadChildren: () =>
            import("./admin/admin.module").then((m) => m.AdminModule),
    },
    {
        path: "",
        canActivate: [AuthGuard],
        component: BaseComponent,
        loadChildren: () =>
            import("./veson/veson.module").then((m) => m.VesonModule),
    },
    {
        path: "",
        canActivate: [AuthGuard],
        component: BaseComponent,
        loadChildren: () =>
            import("./app-auth/app-auth.module").then((m) => m.AppAuthModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
