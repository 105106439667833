import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appElementFocus]'
})
export class ElementFocus {

  constructor(el: ElementRef) {
    setTimeout(() => {
      el.nativeElement.focus();
    }, 100);
  }
}
