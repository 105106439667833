export enum StateKeyEnums {
  payexAPManageSellers = 'payexAPManageSellers',
  payexAPManageSuppliers = 'payexAPManageSuppliers',
  payexAPManagePurchaseOrders = 'payexAPManagePurchaseOrders',
  payexAPManageSupplierInvoices = 'payexAPManageSupplierInvoices',
  payexAPManageNewSupplierInvoice = 'payexAPManageNewSupplierInvoice',
  payexAPManagePODs = 'payexAPManagePODs',
  payexAPManageRecons = 'payexAPManageRecons',
  payexAPManagePayments = 'payexAPManagePayments',
  payexAPManageUnpaidInvoices = 'payexAPManageUnpaidInvoices',
  payexAPManagePaymentHistory = 'payexAPManagePaymentHistory',
  payexAPManagePaymentStatements = 'payexAPManagePaymentStatements',
  payexAPManageCreditNotes = 'payexAPManageCreditNotes',
  payexAPMakePayment = 'payexAPMakePayment',
  payexAPManagePaymentDetail = 'payexAPManagePaymentDetail'
}
