<footer class="footer" [ngClass]="{ 'footer-auth': onFullPage }">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">2021 - 2022 © Global PayEX</div>
            <div class="col-md-6">
                <div class="text-md-right footer-links d-none d-sm-block">
                    <a
                        target="_blank"
                        href="https://www.globalpayex.com/"
                        rel="noopener"
                        >About Us</a
                    >
                    <a
                        target="_blank"
                        href="jhttps://www.globalpayex.com/contact/"
                        rel="noopener"
                        >Help</a
                    >
                    <a
                        target="_blank"
                        href="https://www.globalpayex.com/contact/"
                        rel="noopener"
                        >Contact Us</a
                    >
                </div>
            </div>
        </div>
    </div>
</footer>
