const web_api_root_config = {
    QA: "https://jsw.qa.payex.live/api/",
    // QA_ALT: "https://axisqa.payex.live",
    // NEW_QA: "https://rearchqa.payex.live",
    UAT: "https://jsw.uat.payex.live/api/",
    // NEW_UAT: "https://rearchuat.payex.live",
    // PROD: "https://jswap.payex.live/api/",
    //LOCAL: "http://localhost:8090",
};
const overrideEnvironment = window["overrideEnvironment"];
const app_deploy_environment = "QA";
const web_api_path = "";
const web_api_root =
    overrideEnvironment &&
    overrideEnvironment.basePath &&
    overrideEnvironment.basePath.indexOf("http://") !== -1
        ? overrideEnvironment.basePath
        : web_api_root_config[app_deploy_environment];
const externalLoginUrl =
    overrideEnvironment && overrideEnvironment.externalLoginUrl;
const WEB_TIME_OUT = overrideEnvironment && overrideEnvironment.WEB_TIME_OUT;
const appsBasePath = {};

if (overrideEnvironment && overrideEnvironment.apps) {
    Object.keys(overrideEnvironment.apps).forEach((appName) => {
        const appConfig = overrideEnvironment.apps[appName];
        if (appConfig.url) {
            appsBasePath[appName] = appConfig;
        }
    });
}

export const environment = {
    production: true,
    base: web_api_root,
    MANUAL_RECON_BASE_URL: "https://rearchqa.payex.live/algoriqapi/",
    // MANUAL_RECON_BASE_URL: 'https://rearchuat.payex.live/algoriqapi/',
    // MANUAL_RECON_BASE_URL: 'http://10.104.53.21:30000/algoriqapi/',
    API: {
        PayEXDC: appsBasePath["PayEXDC"] || {
            url: `${web_api_root}/${web_api_path}`,
        },
        PADD: appsBasePath["PADD"] || {
            url: `${web_api_root}/${web_api_path}`,
        },
        Algoriq: appsBasePath["Algoriq"] || {
            url: `${web_api_root}/${web_api_path}`,
        },
        Rearch: appsBasePath["Rearch"] || {
            url: `${web_api_root}/${web_api_path}`,
        },
        JSWAP: appsBasePath["JSWAP"] || {
            url: `${web_api_root}/${web_api_path}`,
        },
    },
    environment_name: app_deploy_environment,
    appName: "PayEXDC",
    externalLoginUrl: externalLoginUrl,
    environmentConfig: overrideEnvironment || {},
    deploymentInfo: {
        date: "2022-11-20",
        version: "v1.1",
        timestatmp: "",
    },
};
