import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "searchFilter",
})
export class SearchFilterPipe implements PipeTransform {
    transform(list: any[], filterText: any): any {
        let filteredList: any = [];
        if (filterText) {
            let newSearchTerm = !isNaN(filterText)
                ? filterText.toString()
                : filterText.toString().toUpperCase();
            let prop;
            return list.filter((item) => {
                for (let key in item) {
                    prop = isNaN(item[key])
                        ? item[key].toString().toUpperCase()
                        : item[key].toString();
                    if (prop.indexOf(newSearchTerm) > -1) {
                        filteredList.push(item);
                        return filteredList;
                    }
                }
            });
        } else {
            return list;
        }
    }
}
