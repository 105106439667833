import { UserRolesEnum } from './stringConstants';
import { StateKeyEnums } from './state-keys';
const menuItems = [
    { stateKey: StateKeyEnums.payexAPManageSellers, routeUrl: '/payexap/sellers', stateDisplayName: 'Manage Sellers', icon: 'ri-store-2-line', children: [], roles: [UserRolesEnum.PADD_ADMIN] },
    // { stateKey: 'buyers', routeUrl: '/buyers', stateDisplayName: 'Buyers', icon: 'ri-layout-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN] },
    { stateKey: StateKeyEnums.payexAPManageSuppliers, routeUrl: '/payexap/suppliers', stateDisplayName: 'Manage Suppliers', icon: 'ri-team-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN] },
    { stateKey: StateKeyEnums.payexAPManagePurchaseOrders, routeUrl: '/payexap/purchase-orders', stateDisplayName: 'Manage Purchase Orders', icon: 'ri-file-list-3-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN, UserRolesEnum.SUPPLIER_ADMIN] },
    { stateKey: StateKeyEnums.payexAPManageSupplierInvoices, routeUrl: '/payexap/invoices', stateDisplayName: 'Manage Supplier Invoices', icon: 'ri-bill-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN, UserRolesEnum.SUPPLIER_ADMIN] },
    { stateKey: StateKeyEnums.payexAPManagePODs, routeUrl: '/payexap/proof-of-deliveries', stateDisplayName: 'Manage PODs', icon: 'ri-survey-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN, UserRolesEnum.SUPPLIER_ADMIN] },
    { stateKey: StateKeyEnums.payexAPManageRecons, routeUrl: '/payexap/recons', stateDisplayName: 'Manage Recon', icon: 'ri-swap-box-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN] },
    {
        stateKey: StateKeyEnums.payexAPManageUnpaidInvoices, routeUrl: '/payexap/manage-payments', stateDisplayName: 'Manage Payments', icon: 'ri-layout-line', children: [
            { stateKey: StateKeyEnums.payexAPManageUnpaidInvoices, routeUrl: '/payexap/manage-payments/unpaid-invoices', stateDisplayName: `Unpaid Invoices`, icon: 'ri-layout-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN] },
            { stateKey: StateKeyEnums.payexAPManageCreditNotes, routeUrl: '/payexap/manage-payments/view-credit-notes', stateDisplayName: `View Credit Notes`, icon: 'ri-layout-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN] },
            { stateKey: StateKeyEnums.payexAPManagePaymentHistory, routeUrl: '/payexap/manage-payments/payments-history', stateDisplayName: `Payments History`, icon: 'ri-layout-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN] }
            // { stateKey: StateKeyEnums.payexAPManagePaymentStatements, routeUrl: '/payexap/manage-payments/view-statements', stateDisplayName: `View Statement`, icon: 'ri-layout-line', children: [], roles: [UserRolesEnum.SELLER_ADMIN] },
        ], roles: [UserRolesEnum.SELLER_ADMIN]
    },
];

export const MenuItems = {
    menuItems,
};
