import { Injectable } from '@angular/core';
import { Config } from '../_store';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  private dateDisplayFormat = Config.dateDisplayFormat;
  private dateSaveFormat = Config.dateSaveFormat;
  private dateDisplayFormatWithTime = Config.dateDisplayFormatWithTime;
  private monthDict = { '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'Jun', '07': 'Jul', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec' };

  constructor() {
  }

  private rightOfString = (str, len) => {
    return str.substr(str.length - len)
  }

  getCurrentTimeStamp() {
    return Date.now();
  }

  getToday() {
    return moment().startOf('day');
  }

  addDaysToDate(date, daysToAdd) {
    return moment(date).add(daysToAdd, 'day');
  }

  addYearsToDate(date, yearsToAdd) {
    return moment(date).add(yearsToAdd, 'year');
  }

  getFormattedDate(date = undefined) {
    return moment(date).format(this.getDateSaveFormat());
  }

  getFormattedDateForDisplay(date = undefined) {
    return date ? moment(date).format(this.getDisplayFormat()) : '';
  }

  getFormattedDateForService(date) {
    return moment(date).format(this.dateSaveFormat);
  }

  getDateSaveFormat() {
    return this.dateSaveFormat;
  }
  
  getSaveDateFromTimestamp(date) {
    return this.getFormattedDateForService(this.getDateFromTimestamp(date));
  }
  
  getDisplayFormat() {
    return this.dateDisplayFormat;
  }
  
  getSaveeDateFromPayments(date) {
    return moment(date, 'DD-MM-YYYY').format(this.dateSaveFormat)
  }
  
  getDateInMoment(date) {
    return moment(date);
  }

  getDateInMomentWithFormat(date, dateFormat = 'YYYY-MM-DD') {
    return moment(date, dateFormat);
  }

  getTimestamp(date?) {
    return moment(date).startOf('day').toDate().getTime();
  }

  getTimestampInSeconds(date?) {
    return this.getTimestamp(date) / 1000;
  }

  getDateFromTimestamp(timeStamp) {
    let tsToConvert = timeStamp;
    if (tsToConvert && tsToConvert.toString().length > 10) {
      // ts in ms
      tsToConvert = tsToConvert / 1000;
    }
    return moment.unix(tsToConvert);
  }

  getDisplayDateFromTimestamp(timestamp) {
    if (!timestamp) {
      return '';
    }
    return this.getFormattedDateForDisplay(this.getDateFromTimestamp(timestamp));
  }

  getFormattedDateTimeForDisplay(timestamp = undefined) {
    if (!timestamp) {
      return '';
    }
    const date = this.getDateFromTimestamp(timestamp);
    return moment(date).format(this.dateDisplayFormatWithTime);
  }

  dateIsNotToday(date1) {
    return this.getDateDiff(this.getToday(), date1) !== 0;
  }

  dateSameOrAfterGivenDate(date1, date2) {
    return moment(date1).isSameOrAfter(moment(date2));
  }

  datesAreSame(date1, date2) {
    return moment(date1).startOf('day').isSame(moment(date2).startOf('day'));
  }

  dateAfterGivenDate(date1, date2) {
    return moment(date1).isAfter(moment(date2));
  }

  getDateDiff(date1, date2) {
    return moment(date1).diff(moment(date2), 'day');
  }

  getOffsetDate(timestamp) {
    return moment(timestamp).utcOffset(330);
  }

  // date1 type is moment
  getStartOfDate(date1: moment.Moment, unit: moment.unitOfTime.StartOf = 'day') {
    return date1.startOf(unit);
  }

  getTimestampWithOffsetForTimeStamp(currTimestamp) {
    return this.getTimestamp(this.getStartOfDate(this.getOffsetDate(currTimestamp)));
  }

  cloneDate(dateToClone) {
    return moment(dateToClone).clone().toDate();
  }

  dateIsBetween(dateToCompare, fromDate, toDate) {
    return moment(dateToCompare).isBetween(this.getStartOfDate(moment(fromDate)), this.getStartOfDate(moment(toDate)), 'day', '[]');
  }
  convertDate(dateToConvert, dateFormat?) {
    return dateFormat ? moment(dateToConvert, dateFormat).startOf('day') : moment(dateToConvert).startOf('day')
  }

  // expected month in MM format i.e. 01, 02 etc
  getMonthTextFromMonthNo(monthNo) {
    return this.monthDict[monthNo];
  }

  // expected input in YYYY-MM format i.e. 2020-10, 2020-11
  formatDateFrom_YYYY_MM_To_MMM_YY(date) {
    const yearMonths = date.split('-');
    const month = yearMonths[1];
    return `${this.getMonthTextFromMonthNo(month)}-${yearMonths[0].substr(2, 2)}`;
  }

  getDateFromBootStrapDate(bootStrapDate) {
    //const date = moment().year(bootStrapDate.year).month(bootStrapDate.month - 2).day(bootStrapDate.day);
    const dateStr = `${bootStrapDate.year}-${this.rightOfString('00' + bootStrapDate.month.toString(), 2)}-${this.rightOfString('00' + bootStrapDate.day.toString(), 2)}`
    const date = moment(dateStr).startOf('day');
    return date;
  }

  getBootstrapDateFromDate(inDateStr) {
    let dateStr = inDateStr;
    if (dateStr.indexOf('T')) {
      inDateStr = dateStr.split('T')[0];
    }
    const splitDates = inDateStr.split('-');
    return {
      year: parseInt(splitDates[0]),
      month: parseInt(splitDates[1]),
      day: parseInt(splitDates[2]),
    }
  }

  getDateFromJsonFormate(date) {
    let jsondate = date.split('-');
    return { year: +jsondate[0], month: +jsondate[1], day: +jsondate[2] };
  }

  isValidDate(momentDate) {
    return moment(momentDate).isValid();
  }

  // Date being sent from Service is not consistent, sometimes we get timestamp, sometimes YYYY-MM format, sometimes, MM-DD-YYYY
  convertDateUnknownFormat(dateToConvert) {
    const indexOfSeparator = dateToConvert.indexOf('-');
    if (indexOfSeparator === 4) {
      //YYYY-
      return moment(dateToConvert, 'YYYY-MM-DD HH:mm:ss');
    } else if (indexOfSeparator === 2) {
      // MM-DD
      return moment(dateToConvert, 'MM-DD-YYYY');
    } else {
      if (dateToConvert.toString().length === 8) {    // ddMMYYYY
        return moment(dateToConvert, 'DDMMYYYY');
      } else {
        // timestamp
        return this.getDateFromTimestamp(parseInt(dateToConvert) / 1000);
      }
    }
  }
  
  getTimestampWithTime(date?) {
    return moment(date).toDate().getTime() / 1000;
  }

}

