<!-- Topbar Start -->
<div class="navbar-custom">
    <div class="container-fluid">
        <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                <img src="assets/images/jsw-logo-white.png" alt="brand" />
            </li>
        </ul>

        <ul class="navbar-nav list-unstyled topnav-menu float-end">
            <li class="dropdown notification-list" ngbDropdown>
                <a
                    class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
                    ngbDropdownToggle
                    id="profileDropdown"
                    href="javascript: void(0);"
                    role="button"
                    aria-haspopup="false"
                    aria-expanded="false"
                >
                    <span class="pro-user-name ml-1">
                        {{ username }}
                    </span>
                </a>
                <div
                    class="dropdown-menu dropdown-menu-right profile-dropdown"
                    aria-labelledby="profileDropdown"
                    ngbDropdownMenu
                >
                    <!-- item-->
                    <div class="dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Welcome !</h6>
                    </div>

                    <!-- item-->
                    <a
                        [routerLink]="['/change-password']"
                        class="dropdown-item notify-item"
                    >
                        <i class="ri-lock-password-line"></i>
                        <span>Change Password</span>
                    </a>

                    <div class="dropdown-divider"></div>

                    <!-- item-->
                    <a
                        href="javascript:void(0);"
                        class="dropdown-item notify-item"
                        (click)="logout()"
                    >
                        <i class="ri-logout-box-line"></i>
                        <span>Logout</span>
                    </a>
                </div>
            </li>

            <!-- <li class="dropdown notification-list">
        <a href="javascript:void(0);" class="nav-link right-bar-toggle waves-effect waves-light"
          (click)="toggleRightSidebar()">
          <i class="fe-settings noti-icon"></i>
        </a>
      </li> -->
        </ul>
        <!-- LOGO -->
        <!-- <div class="logo-box">
            <a [routerLink]="['/home']" class="logo logo-light text-center">
                <span class="logo-sm">
                    <img src="assets/images/payex.png" alt="" height="40" />
                </span>
                <span class="logo-lg">
                    <img src="assets/images/payex.png" alt="" height="40" />
                </span>
            </a>
        </div> -->
        <!-- <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                <button
                    class="button-menu-mobile waves-effect waves-light"
                    (click)="toggleMobileMenu($event)"
                >
                    <i class="fe-menu"></i>
                </button>
            </li>
        </ul> -->
    </div>
</div>
<!-- end Topbar -->
