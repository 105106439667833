import { Injectable } from "@angular/core";
import { EventsService } from "src/app/_services/eventsService";
import { Config } from "src/app/_store";
import { StringConstants } from "src/app/_store/stringConstants";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class LayoutService {
    currentLayOut = {
        isCondensed: true,
    };

    private darkStyles = ["css_app_darkBS", "css_app_darkTheme"];
    versionInfo = `${Config.deploymentInfo.date}-${Config.deploymentInfo.version}`;

    constructor(eventsService: EventsService) {
        eventsService.appEvent.subscribe((eventData) => {
            // if (eventData && eventData.name === StringConstants.EVENTS.USER_LOGOUT) {
            //     if (eventData.data && eventData.data.userLoggedOut) {
            //         this.setThemeMode(false);
            //     }
            // }
        });
    }

    setAuthLayout() {
        document.body.classList.add(...["auth-fluid-pages", "pb-0"]);
    }

    setPageLayout(appName) {
        document.body.classList.remove(...["auth-fluid-pages", "pb-0"]);
    }

    setLoading() {
        document.body.classList.add("loading");
    }

    setIdle() {
        document.body.classList.remove("loading");
    }

    toggleSidebar() {
        this.currentLayOut.isCondensed = !this.currentLayOut.isCondensed;
        document.body.setAttribute(
            "data-sidebar-size",
            this.currentLayOut.isCondensed ? "condensed" : "default"
        );
    }

    initLayout() {
        const appName = environment.appName;
        const themeConfig = Config.themeConfig[appName];
        let title = "";
        if (themeConfig) {
            if (themeConfig.applyDarkTheme) {
                document.body.setAttribute("data-layout-width", "");
            }
        }
        title = `${(themeConfig || {}).title || appName} - ${this.versionInfo}`;
        document.title = title;
    }
}
