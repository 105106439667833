import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventsService } from './eventsService';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private router: Router,
  ) { }

  getDummyObservable(data = {}) {
    const dummy: BehaviorSubject<any> = new BehaviorSubject<any>(data);
    const dummyObserver: Observable<any> = dummy.asObservable();
    return dummyObserver;
  }

  scrollWindow() {
    const element = document.getElementById('content-page');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  routeToUrl(url) {
    this.router.navigateByUrl(url);
  }

  routeToPrevUrl() {
    window.history.back();
  }

  getDefaultPortletOptions(options: any = {}) {
    return Object.assign({}, {
      title: options.title,
      color: 'white',
      text: '',
      headerClass: 'h5 m-0 p-2 border-0 header-title',
      hideClose: true,
      loading: false
    }, options);
  }

  sortCollection(objArr, col, direction = 1) {
    return objArr.sort((a, b) => {
      if (a[col] < b[col]) {
        return direction === 0 ? 1 : -1;
      } else if (a[col] > b[col]) {
        return direction === 0 ? -1 : 1;
      } else {
        return 0;
      }
    });
  }

  getRandomNo(minNo, maxNo) {
    return Math.max(minNo, Math.round(Math.random() * maxNo));
  }

  emptyArrayWithReference(arr) {
    while (arr.length) {
      arr.pop();
    }
  }

  isValidJSON(str) {
    let jsonObj;
    try {
      jsonObj = JSON.parse(str);
    } catch (err) {
    }
    return jsonObj;
  }

  debounce_leading(func, timeout = 300){
    let timer;
    return (...args) => {
      if (!timer) {
        func.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  }
}
