import { Injectable, NgZone } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthenticationService } from "./authenticationService";
import { OfflineService } from "./offlineService";
import { StringConstants } from "../_store/stringConstants";
import { DialogServiceService } from "../_app-core/dialogs/dialog-service.service";
import { CommonService } from "./common.service";

@Injectable({
    providedIn: "root",
})
export class CustomHttpInterceptor implements HttpInterceptor {
    userSessionHasTimedout = false;
    constructor(
        private authenticationService: AuthenticationService,
        private offlineService: OfflineService,
        private dialogsService: DialogServiceService,
        private commonService: CommonService
    ) {}

    private skipErrorThrows = [
        "/app/offer",
        "dashboard/landingPageSummary",
        "/assets/i18n",
        "static/lang-assets/config/lang-config.json",
        "/mngquery",
        "dashboard/download-paymentadvice",
        "/recon",
    ];

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const url = request.url;
        const reqBody = request.body;

        const isPOST = request.method === "POST";
        const action = reqBody && reqBody.action;
        // Variable which checks if user's session timedout and still request is coming to interface
        // Possible on states where multiple calls are made, e.g. payment page
        const sessionTimedout =
            this.authenticationService.getIsUserSessionTimedout();
        if (sessionTimedout) {
            return;
        }
        const isSessionTimedout =
            this.authenticationService.checkLastActivityTime();
        if (isSessionTimedout) {
            this.showSessionTimeout();
            throw new Error("SESSIONTIMEOUT");
        }
        this.authenticationService.setLastActivityTime();
        if (isPOST) {
            if (
                this.authenticationService.currentUserData &&
                this.authenticationService.currentUserData.session
            ) {
                const isExternalReq = url.indexOf("/eipp/") !== -1;
                if (!isExternalReq) {
                    const session = (
                        this.authenticationService.currentUserData || {}
                    ).session;
                    request = request.clone({
                        url,
                        body: reqBody,
                        //headers: request.headers.set('token', session.sessionId)
                    });
                } else {
                    const currentUserData =
                        this.authenticationService.currentUserData || {};
                    const eippSession = currentUserData?.userData?.eippSession;
                    // const eippSession = "cfc09338-03f8-4287-97fc-97484f58d25c_3905584011917387";
                    request = request.clone({
                        url,
                        body: Object.assign(
                            {},
                            reqBody,
                            eippSession ? { session: eippSession } : {}
                        ),
                    });
                }
            }
            const offlineRes = this.offlineService.getDummyResponse(request);
            if (offlineRes) {
                return of(
                    new HttpResponse({ status: 200, body: offlineRes as any })
                );
            }
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.body && event.body.code === "401") {
                        this.authenticationService.logout(true);
                    }
                    // this.errorDialogService.openDialog(event);
                    if (event.body && event.body) {
                        const res: any = event.body;
                        const isSuccess =
                            (res.status || res.success) &&
                            !res.error &&
                            res.status !== "error";
                        const skipThrow =
                            this.skipErrorThrows.find((urlToSkip) => {
                                return event.url.indexOf(urlToSkip) !== -1;
                            }) !== undefined;
                        // if (!skipThrow && !isSuccess) {
                        //     throw new Error(
                        //         res.error ||
                        //             res.msg ||
                        //             res.message ||
                        //             StringConstants.MESSAGES
                        //                 .INVALID_SERVICE_RESPONSE
                        //     );
                        // }
                    }
                    const urlIsForView =
                        event.url &&
                        (event.url.indexOf("/error") === -1 ||
                            event.url.indexOf("invoice_data") === -1);
                    if (!event.body && !urlIsForView) {
                        throw new Error(
                            StringConstants.MESSAGES.SERVER_GENERAL_ERROR
                        );
                    }
                }

                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const errorCode = error && error.status;
                if (errorCode == 401) {
                    if (
                        error.error &&
                        error.error &&
                        error.error.error.indexOf("expired") !== -1
                    ) {
                        if (!this.userSessionHasTimedout) {
                            this.userSessionHasTimedout = true;
                            this.showSessionTimeout();
                        }
                    }
                    this.authenticationService.logout(true);
                } else {
                    if (!navigator.onLine) {
                        this.dialogsService.showServerError("nointernet");
                    } else {
                        let data = {
                            message:
                                error && error.error && error.error.reason
                                    ? error.error.reason
                                    : error.message
                                    ? error.message
                                    : error,
                            status: error.status,
                            header: "Error",
                        };
                        if (
                            errorCode === 404 ||
                            data.message.indexOf("document not found") !== -1
                        ) {
                            this.dialogsService.showErrorModal(
                                StringConstants.MESSAGES.DOC_NOT_FOUND
                            );
                        } else if (
                            data.message.indexOf("Oops! There seems") !== -1 ||
                            data.message.indexOf("Unknown") !== -1
                        ) {
                            this.dialogsService.showServerError("servererror");
                        } else {
                            // this.dialogsService.showErrorModal(data.message);
                        }
                    }
                    return throwError(error);
                }
            })
        );
    }

    showSessionTimeout() {
        this.dialogsService.showErrorModal(
            "Your session has timed-out, Please login again to continue."
        );
        setTimeout(() => {
            this.userSessionHasTimedout = false;
        }, 2000);
    }
}
