<div class="modal-header">
    <button
        type="button"
        class="modal-button close"
        aria-describedby="modal-title"
        (click)="activeModal.close(false)"
    >
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</div>
<div class="modal-body">
    <p>Are you sure want to impersonate {{ message }}?</p>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="modal-button btn btn-secondary"
        ngbAutofocus
        appElementFocus
        (click)="activeModal.close(false)"
    >
        Cancel
    </button>
    <button
        type="button"
        class="modal-button btn btn-secondary"
        ngbAutofocus
        appElementFocus
        (click)="impersonate()"
    >
        Impersonate
    </button>
</div>
