<!-- Right Sidebar -->
<div class="right-bar" (clickOutside)="hide()">
    <div data-simplebar class="h-100">
        <div class="slimscroll-menu" appSlimScroll>
            <!-- User box -->
            <div class="user-box">
                <div class="user-img">
                    <img
                        src="assets/images/user-img.png"
                        alt="user-img"
                        class="rounded-circle img-fluid"
                    />
                    <a href="javascript:void(0);" class="user-edit"
                        ><i class="mdi mdi-pencil"></i
                    ></a>
                </div>

                <h5>
                    <a href="javascript: void(0);">{{ username }}</a>
                </h5>
                <p class="text-muted mb-0"><small>Admin Head</small></p>
            </div>

            <div class="inbox-widget pl-3 pr-3">
                <h5 class="mt-0"></h5>
                <div class="inbox-item" *ngFor="let seller of sellers">
                    <div class="inbox-item-img">
                        <img
                            src="assets/images/user-img.png"
                            class="rounded-circle"
                            alt=""
                        />
                        <i class="online user-status"></i>
                    </div>
                    <p class="inbox-item-author">
                        <a href="javascript: void(0);" class="text-dark">{{
                            seller.sellerName
                        }}</a>
                    </p>
                    <p class="inbox-item-text">{{ seller.address }}</p>
                </div>
            </div>
        </div>
        <!-- end slimscroll-menu-->
    </div>
    <!-- <div class="rightbar-title">
    <a href="javascript:void(0);" class="right-bar-toggle float-right" (click)="hide();">
      <i class="fe-x noti-icon"></i>
    </a>
    <h4 class="m-0 text-white">Settings</h4>
  </div> -->
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>
