<div class="modal-header">
    <h4 class="text-white">{{ title }}</h4>
    <button
        type="button"
        class="modal-button close"
        aria-describedby="modal-title"
        (click)="activeModal.close(false)"
    >
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</div>
<div class="modal-body">
    <p>
        <strong>{{ message }}</strong>
    </p>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="modal-button btn btn-secondary"
        ngbAutofocus
        appElementFocus
        (click)="closePrompt()"
    >
        Close
    </button>
</div>
