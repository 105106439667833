import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AdminDataService } from "src/app/_services/admin.data.service";
import { AuthenticationService } from "src/app/_services/authenticationService";
import { DialogServiceService } from "src/app/_app-core/dialogs/dialog-service.service";

@Component({
    selector: "app-reject-invoice",
    templateUrl: "./reject-invoice.component.html",
})
export class RejectInvoiceComponent implements OnInit {
    currentUserData;
    reason;
    emptyReason = false;

    constructor(
        public activeModal: NgbActiveModal,
        private adminService: AdminDataService,
        private authService: AuthenticationService,
        private dialogsService: DialogServiceService
    ) {}

    ngOnInit(): void {}

    userReason(event: KeyboardEvent) {
        this.reason = (event.target as HTMLInputElement).value;
        // if (this.reason === "") {
        //     this.emptyReason = true;
        // }
    }

    closeModal() {
        this.activeModal.close(false);
        this.emptyReason = false;
    }

    rejectInvoice() {
        if (this.reason === undefined) {
            this.emptyReason = true;
        }

        console.log(this.emptyReason);

        const acceptParams = this.adminService.getVesonParams();
        console.log(acceptParams);

        this.currentUserData = this.authService.currentUserData;
        const userData = this.currentUserData;
        let session;
        if (userData === null) {
            session = {
                sessionId: sessionStorage.getItem("sessionId"),
            };
        } else {
            session = userData.loginData.session;
        }

        const reqData = {
            action: acceptParams.action,
            barcode: acceptParams.barcode,
            session: session,
            reason: this.reason,
        };
        //console.log(reqData);

        console.log(this.reason + " reason here");

        if (this.reason !== "" && this.emptyReason === false) {
            this.activeModal.close(true);
            this.dialogsService.showWaitDialog();
            this.adminService.shippingInvoiceAction(reqData).subscribe(
                (data) => {
                    if (
                        data.status === "successful" ||
                        data.status === "success"
                    ) {
                        console.log(data);
                        setTimeout(() => {
                            this.dialogsService.hideWaitDialog();
                        }, 400);

                        const title = "Rejected";
                        const msg =
                            "Shipping invoice has been rejected successfully";
                        this.dialogsService.showInfoDialog(title, msg);
                    }
                },
                (error) => {
                    console.log(error);
                    this.dialogsService.hideWaitDialog();
                }
            );
        }
    }
}
