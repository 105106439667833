import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpUtilityService } from ".";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "./appConfigService";

@Injectable({
    providedIn: "root",
})
export class AdminDataService {
    vendorCode: any;
    barcode;
    acceptAction;
    venCode;
    buyerId;
    constructor(
        private appConfigService: AppConfigService,
        private httpUtilityService: HttpUtilityService,
        private http: HttpClient
    ) {}

    public setVesonParams(barcode, action) {
        this.barcode = barcode;
        this.acceptAction = action;
    }

    public getVesonParams() {
        const params = {
            barcode: this.barcode,
            action: this.acceptAction,
        };
        return params;
    }

    public setImpersonateVendorCode(code) {
        this.vendorCode = code;
    }

    public getImpersonateVendorCode() {
        return this.vendorCode;
    }

    public setVendorCode(code) {
        this.venCode = code;
    }

    public getVendorCode() {
        return this.venCode;
    }

    public setBuyerCode(buyerId) {
        this.buyerId = buyerId;
    }

    public getBuyerCode() {
        return this.buyerId;
    }

    public getMngQueryOutput(query) {
        return this.httpUtilityService
            .httpPost({ query: query }, this.appConfigService.urls)
            .pipe(
                map((data) => {
                    return data?.data;
                })
            );
    }

    public refreshPaymentAdvice() {
        return this.httpUtilityService
            .httpPost({}, this.appConfigService.urls)
            .pipe(
                map((data) => {
                    return data?.data;
                })
            );
    }

    public getDashboadUpdatesStats(sellerCode) {
        return this.httpUtilityService
            .httpPost({ sellerCode }, this.appConfigService.urls)
            .pipe(
                map((data) => {
                    return data?.data;
                })
            );
    }

    public resetDashboardUpdateStat(statId) {
        return this.httpUtilityService
            .httpPost({ statId }, this.appConfigService.urls)
            .pipe(
                map((data) => {
                    return data?.data;
                })
            );
    }

    public getCollectionNames(statsCollection) {
        return this.httpUtilityService
            .httpPost({ statsCollection }, this.appConfigService.urls)
            .pipe(
                map((data) => {
                    return data?.data;
                })
            );
    }

    public showCollectionDocuments({ collectionName, statsCollection, query }) {
        return this.httpUtilityService
            .httpPost(
                { collectionName, statsCollection, query },
                this.appConfigService.urls
            )
            .pipe(
                map((data) => {
                    return data?.data;
                })
            );
    }

    public resetStatsCollection({ collectionName, statsCollection }) {
        return this.httpUtilityService
            .httpPost(
                { collectionName, statsCollection },
                this.appConfigService.urls
            )
            .pipe(
                map((data) => {
                    return data?.data;
                })
            );
    }

    public getCollectionCount({ collectionName, statsCollection }) {
        return this.httpUtilityService
            .httpPost(
                { collectionName, statsCollection },
                this.appConfigService.urls
            )
            .pipe(
                map((data) => {
                    return data?.data;
                })
            );
    }

    public getAnalytics(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.ANALYTICS)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getVendors(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.VENDORS)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public resendEmails(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.RESENDEMAILS)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getVendorDetails(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.VENDORDETAILS)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getAdmins(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.LISTADMINS)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public addAdmins(reqData) {
        // const headers = new HttpHeaders({
        //     Authorization: "Basic anN3X2FwaV9hY2Nlc3M6MjEzNDU2Nzg",
        // });

        // const requestOptions = { headers: headers };

        // console.log(requestOptions);
        // return this.http
        //     .post(this.appConfigService.urls.ADDADMIN, reqData, requestOptions)
        //     .pipe(
        //         map((data) => {
        //             return data;
        //         })
        //     );

        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.CREATEADMIN)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public assignVendor(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.ASSIGN)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public adminImpersonate(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.IMPERSONATE)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getReports(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.REPORTS)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getCompanyConfig(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.FETCHCONFIGBUYER)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public shippingInvoiceAction(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.VESONINVOICEACTION)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public submitDocumentVeson(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.ANALYTICS)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public getShippingInvoice(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.SHIPPINGINVOICES)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    public submitSupportingDocs(reqData) {
        return this.httpUtilityService
            .httpPost(reqData, this.appConfigService.urls.SUBMITSUPPORTINGDOCS)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
