import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { map, timeout } from "rxjs/operators";
import { EventsService } from "./eventsService";
import { StringConstants } from "../_store/stringConstants";
@Injectable({
    providedIn: "root",
})
export class HttpUtilityService {
    static httpPost(req: any, url: any) {
        throw new Error("Method not implemented.");
    }
    private localHttp: HttpClient;

    constructor(
        private http: HttpClient,
        private eventsService: EventsService,
        handler: HttpBackend
    ) {
        this.localHttp = new HttpClient(handler);
    }

    httpPost(
        req,
        reqURL,
        options = {},
        uiOptions = { showWait: true }
    ): Observable<any> {
        //this.setAppBusy(reqURL);
        return this.http.post(reqURL, req, options).pipe(
            map((response) => {
                if (uiOptions.showWait) {
                    this.setAppIdle();
                }
                return response;
            })
        );
    }

    httpGet(reqURL, options): Observable<any> {
        return this.http.get(reqURL, options).pipe(
            map((response) => {
                return response;
            })
        );
    }

    httpMultiPart(reqURL, req) {
        this.setAppBusy(true);
        const httpOptions = {
            headers: new HttpHeaders({
                // 'Content-Type': 'multipart/form-data'
                //'Content-Type': 'application/json'
                //'token': sessionId
                //'Content-Type': 'multipart/form-data',
                //'Content-Type': 'application/json',
                //'Accept': 'text/plain'
            }),
        };
        return this.localHttp.post(reqURL, req, httpOptions).pipe(
            map((response) => {
                this.setAppBusy(false);
                return response;
            })
        );
    }

    httpBlob(reqURL, req) {
        this.setAppBusy(true);
        const httpOptions = {
            headers: new HttpHeaders({
                // 'Content-Type': 'multipart/form-data'
                //'Content-Type': 'application/json'
                //'token': sessionId
                //'Content-Type': 'multipart/form-data',
                //'Content-Type': 'application/json',
                //'Accept': 'text/plain'
            }),
            responseType: "blob" as "json",
        };
        return this.localHttp.post(reqURL, req, httpOptions).pipe(
            map((response) => {
                this.setAppBusy(false);
                return response;
            })
        );
    }

    private setAppBusy(reqUrl) {
        this.eventsService.triggerEvent(StringConstants.EVENTS.APP_BUSY, {
            reqUrl,
        });
    }

    private setAppIdle() {
        this.eventsService.triggerEvent(StringConstants.EVENTS.APP_IDLE);
    }
}
