import { Component, OnInit } from '@angular/core';

import { Inbox } from './rightsidebar.model';

import { inboxData } from './data';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss']
})
export class RightsidebarComponent implements OnInit {
  sellers = [];
  selectedSeller:any = {};
  username = '';
  constructor(
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    /**
     * fetches data
     */
    this._fetchData();
  }

  /**
   * Hide the sidebar
   */
  public hide() {
    document.body.classList.remove('right-bar-enabled');
  }

  private _fetchData() {
    const userData = this.authService.currentUserData;
    this.username = userData.username;
    this.sellers = userData.sellers;
    const selectedSeller = userData.selectedSeller;
  }

  
}
