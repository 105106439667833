<div class="loader-wrapper">
  <div class="loader-content" *ngIf="signatureError">
    <div class="loader-text">
      <i class="ri-error-warning-fill"></i
      ><span class="ml-2">Invalid Signature</span>
    </div>
    <div class="loader-cta my-4">
      <a routerLink="/submit-invoice">
        <button class="btn btn-error">Resubmit Invoice</button>
      </a>
    </div>
  </div>

  <div class="loader-content error" *ngIf="fileTypeError">
    <div class="loader-text">
      <i class="ri-error-warning-fill"></i
      ><span class="ml-2">File Format Not Supported</span>
      <p class="text-white my-4">Allowed File type: pdf, json</p>
    </div>
    <div class="loader-cta my-4">
      <a routerLink="/submit-invoice">
        <button class="btn btn-error">Resubmit Invoice</button>
      </a>
    </div>
  </div>

  <div class="loader-content loading" *ngIf="validateSignature">
    <div class="loader-text">
      <span class="ml-2">Validating Signature</span>
      <p class="text-white my-4">Please wait a few minutes</p>
    </div>
    <div class="loader-cta my-4">
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          style="width: 75%"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</div>
