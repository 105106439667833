import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    private subAppEvent: BehaviorSubject<any> = new BehaviorSubject(null);
    public appEvent = this.subAppEvent.asObservable();

    constructor(
    ) {
    }

    triggerEvent(eventName, eventData = {}){
        this.subAppEvent.next({
            name: eventName,
            data: eventData
        });
    }
}
