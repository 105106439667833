import {
    Component,
    OnInit,
    AfterViewInit,
    ElementRef,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    OnChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import MetisMenu from "metismenujs";
import { AuthenticationService } from "src/app/_services";
import { AppConfigService } from "src/app/_services/appConfigService";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() isCondensed = false;
    @Input() options: any = {};
    @Output() mobileMenuButtonClicked = new EventEmitter();
    @Output() settingsButtonClicked = new EventEmitter();
    menu: any;
    menuItems = [];
    openMobileMenu: boolean;
    currentUserPermission;
    canViewAll = false;
    onlyShipping = false;
    isLimited = false;
    userData;

    @ViewChild("sideMenu", { static: false })
    sideMenu?: ElementRef<HTMLElement>;

    constructor(
        private authService: AuthenticationService,
        private router: Router
    ) {}

    ngOnInit() {
        this.currentUserPermission =
            this.authService.currentUserData.loginData.user.permissions[0];
        this.userData = this.authService.currentUserData;

        if (this.currentUserPermission === "Basic") {
            this.canViewAll = false;
            this.onlyShipping = false;
            this.isLimited = true;
        } else if (this.currentUserPermission === "Shipping Team") {
            this.canViewAll = false;
            this.onlyShipping = true;
            this.isLimited = false;
        } else {
            this.canViewAll = true;
            this.onlyShipping = false;
            this.isLimited = false;
        }
        //console.log(this.currentUserPermission);
        // this.getMenuItems();
    }

    private setMenu() {
        setTimeout(() => {
            this.menu = new MetisMenu("#side-menu");
        }, 100);
        this._activateMenuDropdown();
    }

    ngAfterViewInit() {
        this.setMenu();
    }

    ngOnChanges() {
        if (!this.isCondensed && this.sideMenu) {
            this.setMenu();
        } else if (this.menu) {
            this.menu.dispose();
        }
    }

    /**
     * Activates the menu dropdown
     */
    _activateMenuDropdown() {
        const links = document.getElementsByClassName("side-nav-link-ref");
        let menuItemEl = null;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < links.length; i++) {
            // tslint:disable-next-line: no-string-literal
            if (window.location.pathname === links[i]["pathname"]) {
                menuItemEl = links[i];
                break;
            }
        }

        if (menuItemEl) {
            menuItemEl.classList.add("active");

            const parentEl = menuItemEl.parentElement;
            if (parentEl) {
                parentEl.classList.add("active");

                const parent2El = parentEl.parentElement;
                if (parent2El) {
                    parent2El.classList.add("in");
                }

                const parent3El = parent2El.parentElement;
                if (parent3El) {
                    parent3El.classList.add("active");
                    parent3El.firstChild.classList.add("active");
                }
            }
        }
    }

    /**
     * Toggles the right sidebar
     */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        console.log("clicked " + JSON.stringify(event));
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    getMenuItems() {
        // this.menuItems = this.authService.getMenuItemsForUser();
    }

    logout() {
        const session = this.userData.loginData.session;
        let req;
        if (this.userData === null) {
            this.router.navigate(["signin"]);
        } else {
            req = {
                session: session,
            };

            this.authService.logout(req);
            this.router.navigate(["signin"]);
        }
    }
}
