import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthService } from "src/app/_app-core/services/auth/auth.service";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    displayName: any;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private router: Router
    ) {}

    accountLogout() {
        const userData = {
            session: { sessionId: localStorage.getItem("userSession") },
        };
        this.authService.logOut(userData).subscribe(
            (res) => {
                const responseData: any = res;
                if (responseData.status === "ok") {
                    localStorage.clear();
                    this.router.navigateByUrl("/");
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    ngOnInit(): void {
        this.displayName = localStorage.getItem("displayName");
    }
}
